//
// Copyright 2018 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@import "@material/feature-targeting/functions";
@import "@material/feature-targeting/mixins";
@import "./variables";
@import "./functions";

@mixin mdc-shape-radius($radius, $rtl-reflexive: false, $query: mdc-feature-all()) {
  $feat-structure: mdc-feature-create-target($query, structure);

  @include mdc-feature-targets($feat-structure) {
    // Even if $rtl-reflexive is true, only emit RTL styles if we can't easily tell that the given radius is symmetrical
    $needs-flip: $rtl-reflexive and length($radius) > 1;

    @if ($needs-flip) {
      /* @noflip */
    }

    border-radius: mdc-shape-prop-value($radius);

    @if ($needs-flip) {
      @include mdc-rtl {
        /* @noflip */
        border-radius: mdc-shape-flip-radius(mdc-shape-prop-value($radius));
      }
    }
  }
}
