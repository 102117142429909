//
// Copyright 2016 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

$mdc-ripple-fade-in-duration: 75ms !default;
$mdc-ripple-fade-out-duration: 150ms !default;
$mdc-ripple-translate-duration: 225ms !default;
$mdc-states-wash-duration: 15ms !default;

// Notes on states:
// * focus takes precedence over hover (i.e. if an element is both focused and hovered, only focus value applies)
// * press state applies to a separate pseudo-element, so it has an additive effect on top of other states
// * selected/activated are applied additively to hover/focus via calculations at preprocessing time

$mdc-ripple-dark-ink-opacities: (
  hover: .04,
  focus: .12,
  press: .12,
  selected: .08,
  activated: .12
) !default;

$mdc-ripple-light-ink-opacities: (
  hover: .08,
  focus: .24,
  press: .24,
  selected: .16,
  activated: .24
) !default;

// Legacy

$mdc-ripple-pressed-dark-ink-opacity: .16 !default;
$mdc-ripple-pressed-light-ink-opacity: .32 !default;
